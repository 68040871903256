<template>
  <div class="upLoad">
    <!-- 上传组件 -->
    <!-- btn -->
    <div class="upLoad_module_btn" v-show="upStyle == 'btn'">
      <div class="upLoad_btn">
        <input class="upLoad_input" type="file" @change="shangchuan($event)"/>
        <el-button class="btn btn_blue upLoad_originalbtn">{{ upTitle }}</el-button>
        <progress-dialog v-if="progressVisible" :percentage="percentage"></progress-dialog>
      </div>
    </div>
    <!-- btn 带img-->
    <div class="upLoad_module_btn" v-show="upStyle == 'btnImg'">
      <div class="upLoad_btn">
        <input class="upLoad_input" type="file" @change="shangchuan($event)"/>
        <el-button class="btn btn_blue upLoad_originalbtn">{{ upTitle }}</el-button>
        <progress-dialog v-if="progressVisible" :percentage="percentage"></progress-dialog>
      </div>
      <ul class="upLoad_preul" v-show="upcallbackUrl == ''">
        <li>
          <img class="upLoad_url" :src="upcallbackUrl" alt/>
          <span class="upLoad_txt">{{ upcallbackTxt }}</span>
          <i class="upLoad_icon" @click="delectObj('btnImg')"></i>
        </li>
      </ul>
    </div>
    <!-- 头像 -->
    <div class="upLoad_module_photo upLoad_module_list" v-show="upStyle == 'btnPhoto'">
      <div class="upLoad_wrap upload">
        <input class="upLoad_input" type="file" @change="shangchuan($event)"/>
        <span v-if="upcallbackUrl == ''">{{ upTitle }}</span>
        <img class="upLoad_url" v-show="upcallbackUrl" :src="upcallbackUrl" alt/>
        <progress-dialog v-if="progressVisible" :percentage="percentage"></progress-dialog>
        <!-- <div class="upLoad_layer"></div>
        <i class="el-icon-delete upLoad_delect" @click="delectObj('btnList', item)"></i>-->
      </div>
    </div>
    <!-- 照片墙 -->
    <div class="upLoad_module_list" v-show="upStyle == 'btnList'">
      <ul class="upLoad_list">
        <li class="upLoad_list_big" v-for="(item, index) in upcallbackList" :Key="index">
          <el-image
            title="点击预览"
            class="upLoad_url upLoad_big"
            fit="contain"
            :src="item"
            v-if="['png', 'jpeg', 'jpg', 'JPG'].includes(item.substring(item.lastIndexOf('.') + 1))"
            :preview-src-list="upcallbackList"
          ></el-image>
          <i class="delete" @click="delectObj('btnList', index)"></i>
        </li>
        <li class="upLoad_list_big" v-show="!uploadDisabled" v-if="isShow">
          <div class="upLoad_wrap upload">
            <input
              class="upLoad_input"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              @change="shangchuan($event)"
              οnclick="f.outerHTML=f.outerHTML"
            />
            <span class="up_title">{{ upTitle }}</span>
            <progress-dialog v-if="progressVisible" :percentage="percentage"></progress-dialog>
            <!-- <img class="upLoad_icon" src="../assets/images/icons/icon-upload.png" alt=""> -->
          </div>
        </li>
      </ul>
    </div>
    <!--    附件-->
    <div class="upLoad_module_list" v-show="upStyle == 'accessory'">
      <ul class="upLoad_list">
        <li class="upLoad_list_big" v-for="(item, index) in upcallbackList" :Key="index">
          <a target="_blank" v-if="['pdf', 'PDF'].includes(item.substring(item.lastIndexOf('.') + 1))" :href="item">
            <img
              src="../../assets/img/backStageImg/pdf-logo.png"
              alt
              :title="decodeURI(item.substring(item.lastIndexOf('/') + 1))"
            />
          </a>
          <a
            target="_blank"
            v-if="['docx', 'DOCX'].includes(item.substring(item.lastIndexOf('.') + 1))"
            :href="wordUrl + item"
          >
            <img
              src="../../assets/img/backStageImg/word-logo.png"
              alt
              :title="decodeURI(item.substring(item.lastIndexOf('/') + 1))"
            />
          </a>
          <i class="delete" @click="delectObj('btnList', index)"></i>
        </li>
        <li class="upLoad_list_big" v-if="isAccessory">
          <div class="upLoad_wrap_accessory upload">
            <input
              class="upLoad_input_accessory"
              type="file"
              accept=".pdf,.docx"
              @change="shangchuan($event)"
              οnclick="f.outerHTML=f.outerHTML"
            />
            <span class="up_title">{{ upTitle }}</span>
            <progress-dialog v-if="progressVisible" :percentage="percentage"></progress-dialog>
            <!-- <img class="upLoad_icon" src="../assets/images/icons/icon-upload.png" alt=""> -->
          </div>
        </li>
      </ul>
    </div>
    <!--    视频-->
    <div class="upLoad_module_list" v-show="upStyle == 'videoList'">
      <ul class="upLoad_list">
        <li class="upLoad_list_big" v-for="(item, index) in upcallbackList" :Key="index">
          <a target="_blank" :href="item">
            <!--            <img class="videoLogo" src="../assets/images/upload/videoLogo.png" alt-->
            <!--                 :title="decodeURI(item.substring(item.lastIndexOf( '/' ) + 1))"/>-->
          </a>
          <i class="delete" @click="delectObj('btnList', index)"></i>
        </li>
        <li class="upLoad_list_big">
          <div class="upLoad_wrap upload" v-if="isShow">
            <input
              class="upLoad_input"
              type="file"
              accept="video/*"
              @change="shangchuan($event)"
              οnclick="f.outerHTML=f.outerHTML"
            />
            <span class="up_title">{{ upTitle }}</span>
            <progress-dialog v-if="progressVisible" :percentage="percentage"></progress-dialog>
          </div>
        </li>
      </ul>
    </div>
    <!-- 图标 -->
    <div class="upLoad_module_icon" v-show="upStyle == 'btnIcon'">
      <div class="upLoad_wrap">
        <input v-if="upcallbackUrl == ''" class="upLoad_input" type="file" @change="shangchuan($event)"/>
        <span class="up_title" v-if="upcallbackUrl == ''">
          {{ upTitle }}
        </span>
        <div class="img_wrap" v-show="upcallbackUrl">
          <el-image
            class="upLoad_url"
            :src="upcallbackUrl"
            height="40"
            width="40"
            :preview-src-list="upcallbackUrl.split(',')"
          ></el-image>
          <span class="icon_delete" @click="delectObj('btnIcon')"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import progressDialog from './progressDialogs.vue';
import API from '@apis/common';
import utils from '@utils/index';
var COS = require('cos-js-sdk-v5');
var cos = new COS({
  getAuthorization: async function (options, callback) {
    // console.log(options);
    // 异步获取临时密钥
    await API.getCosToken().then(data => {
      if (!data.credentials) return console.error('credentials invalid');
      var credentials = data && data.credentials;
      this.resultData = data;
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime // 时间戳，单位秒，如：1580000900
      });
    });
  }
});
export default {
  name: 'upLoads',
  props: ['upStyle', 'upTitle', 'callbackUrl', 'callbackList', 'uploadDisabled'],
  components: {
    progressDialog
  },
  watch: {
    callbackUrl(val) {
      if (val) {
        this.upcallbackUrl = val;
      }
    },
    callbackList: {
      handler(val) {
        if (val) {
          this.upcallbackList = val;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      Bucket: 'slyl-edu-static-1301295327',
      Region: 'ap-beijing',
      upcallbackUrl: '',
      upcallbackTxt: '',
      upcallbackList: [],
      percentage: 0,
      progressVisible: false,
      isShow: true,
      isAccessory: true,
      wordUrl: 'http://view.officeapps.live.com/op/view.aspx?src=',
      imgformData: {
        prefix: '',
        description: '',
        type: '',
        originalFilename: '',
        filePath: ''
      },
      resultData: {},
      mimeType: ['jpg', 'png', 'jpeg', 'docx', 'pdf', 'mp4', '3gp', 'mov', 'wmv', 'mpeg', 'flv', 'ppt', 'pptx']
    };
  },
  mounted() {
    // this.upcallbackUrl = this.callbackUrl;
  },
  methods: {
    shangchuan(e) {
      // todo 待完善
      var _this = this;
      var file = e.target.files[0];
      if (!file) return;
      // let mimeType = await utils.getFileMimeType(file)
      /* 限制文件上传类型 */
      let suffixName = file.name.substring(file.name.lastIndexOf('.') + 1); /* 得到文件后缀名 */
      let index = file.name.indexOf('.'),
      name = file.name.substr(0, index) + new Date().getTime(),
      fileName = this.$md5(name) + '.' + suffixName;
      // if (suffixName !== 'jpg' && suffixName !== 'png' && suffixName !== 'docx' && suffixName !== 'pdf' && suffixName !== 'svg') {
      if (!this.mimeType.includes(suffixName)) {
        this.$message.warning('上传文件只能是 jpg、png、jpeg的格式!'); //、word、pdf
        return;
      }
      /* 限制文件上传大小 */
      // let isLtSize = file.size / 1024 / 1024 < 10;
      // if (!isLtSize) {
      //   this.$message.warning("上传文件不能超过 10MB!");
      //   return;
      // }
      cos.putObject(
        {
          //上传对象
          Bucket: this.Bucket, //存储桶
          Region: this.Region, //存储桶所在地域
          StorageClass: 'STANDARD',
          Key: '/upload/images/' + fileName,
          // Key: '/upload/files/' + file.name,
          Body: file,
          onProgress: function (progressData, callback) {
            //进度的回调函数
            _this.progressVisible = true;
            _this.percentage = parseInt((progressData.loaded / progressData.total).toFixed(2)) * 100;
          }
        },
        function (err, data) {
          _this.progressVisible = false;
          cos.getObjectUrl(
            {
              //获取不带签名的对象的 Url
              Bucket: _this.Bucket,
              Region: _this.Region,
              Key: '/upload/images/' + fileName,
              // Key: '/upload/files/' + file.name,
              Sign: false
            },
            async function (err, data) {
              if (
                _this.upStyle == 'btn' ||
                _this.upStyle == 'btnImg' ||
                _this.upStyle == 'btnPhoto' ||
                _this.upStyle == 'btnIcon'
              ) {
                // 一个
                _this.upcallbackUrl = data.Url;
                _this.upcallbackTxt = file.name;
                _this.$emit('up-success', _this.upcallbackUrl);
              }
              if (_this.upStyle == 'btnList' || _this.upStyle == 'videoList' || _this.upStyle == 'accessory') {
                // 多个
                // if (_this.upcallbackList.length == 0) {
                _this.imgformData.prefix = 'doctorIcon';
                _this.imgformData.description = 'pc端上传的图片';
                _this.imgformData.type = '20';
                _this.imgformData.originalFilename = file.name;
                _this.imgformData.filePath = '/upload/files/' + file.name;
                // _this.upcallbackList.push(data.Url)
                // } else {
                if (_this.upcallbackList.includes(data.Url) == false) {
                  _this.upcallbackList.push(data.Url);
                } else {
                  _this.$message.error('请勿上传重复文件');
                }
                // }
                _this.upcallbackTxt = file.name;
                _this.$emit('up-success', _this.upcallbackList, _this.upcallbackTxt);
              }
            }
          );
        }
      );
      e.target.value = '';
    },
    delectObj(type, index) {
      if (type == 'btnImg' || type == 'btnPhoto' || type == 'btnIcon') {
        this.upcallbackUrl = '';
        this.upcallbackTxt = '';
        this.$emit('up-success', this.upcallbackUrl);
      } else if (type == 'btnList') {
        this.upcallbackList.splice(index, 1);
        this.$emit('up-success', this.upcallbackList, this.upcallbackTx, '', index);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
// .up_title {
// line-height: 16px;
// }
.upLoad_module_btn {
	width: 100%;
	.upLoad_btn {
		position: relative;
		width: 80px;
		height: 40px;
		.upLoad_input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 999;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
		.upLoad_originalbtn {
			position: absolute;
			top: 0;
			left: 0;
			background: #409eff;
		}
		.upLoad_originalbtn:hover {
			opacity: .9;
		}
	}
	.upLoad_preul {
		box-sizing: border-box;
		margin-top: 10px;
		padding: 10px;
		width: 100%;
		height: 100px;
		border: 1px solid #c0ccda;
		border-radius: 6px;
		background-color: #fff;
		li {
			position: relative;
			.upLoad_url {
				width: 80px;
				height: 80px;
				background-size: 100% 100%;
				vertical-align: middle;
			}
			.upLoad_txt {
				margin-left: 7px;
				color: #606266;
			}
			.upLoad_icon {
				position: absolute;
				top: 1px;
				right: 2px;
				display: inline-block;
				width: 16px;
				height: 16px;
				background: url("../../assets/img/backStageImg/delete-icon.png");
				cursor: pointer;
			}
		}
	}
}
.upLoad_module_photo {
	.upLoad_wrap {
		position: relative;
		overflow: hidden;
		cursor: pointer;
		.upLoad_layer {
			position: absolute;
			top: 0;
			left: 0;
			display: none;
			width: 148px;
			height: 148px;
			background: rgba(0, 0, 0, .6);
		}
		.upLoad_delect {
			position: absolute;
			top: 50%;
			left: 50%;
			display: none;
			color: #fff;
			font-size: 20px;
			transform: translate(-10px, -10px);
		}
	}
	.upLoad_wrap:hover .upLoad_layer,
	.upLoad_wrap:hover .upLoad_delect {
		display: block;
	}
}
.upLoad_module_list {
	display: flex;
	.upLoad_list {
		display: flex;
		flex-flow: wrap;
		.upLoad_list_big {
			position: relative;
			margin-right: 5px;
			margin-bottom: 18px;
			// width: 88px;
			width: 100%;
			height: 88px;
			text-align: center;
			cursor: pointer;
			img {
				box-sizing: border-box;
				// height: auto;
				max-width: 100%;
				max-height: 100%;
				width: auto;
				border-radius: 6px;
			}
			.delete {
				position: absolute;
				top: -7px;
				right: -5px;
				z-index: 999;
				display: block;
				width: 16px;
				height: 16px;
				background: url("../../assets/img/backStageImg/delete-icon.png") no-repeat;
			}
			.upLoad_icon {
				position: absolute;
				top: 1px;
				right: 2px;
				display: inline-block;
				width: 16px;
				height: 16px;
				background: url("../../assets/img/backStageImg/delete-icon.png");
				cursor: pointer;
			}
			.upLoad_big {
				width: 100%;
				height: 100%;
				border-radius: 6px;
			}
		}
	}
	.upLoad_wrap_accessory {
		// line-height: 146px;
		position: relative;
		box-sizing: border-box;
		// width: 245px;
		height: 132px;
		border: 1px solid #d8e0f0;
		border-radius: 18px;
		.upLoad_input_accessory {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 999;
			// width: 245px;
			height: 132px;
			opacity: 0;
			cursor: pointer;
		}
		span {
			width: 100%;
		}
	}
	.upLoad_wrap {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border: 1px solid #d8e0f0;
		border-radius: 18px;
		// line-height: 146px;
		vertical-align: top;
		span {
			width: 100%;
		}
		.upLoad_input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 999;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
		}
		.upLoad_url {
			width: 100%;
			height: 100%;
		}
		.upLoad_icon {
			position: absolute;
			top: 1px;
			right: 2px;
			display: inline-block;
			width: 16px;
			height: 16px;
			background: url("../../assets/img/backStageImg/delete-icon.png");
			cursor: pointer;
		}
	}
}
.upLoad_module_icon {
	float: left;
	width: 110px;
	.upLoad_wrap {
		position: relative;
		float: left;
		margin-right: 8px;
		height: 40px;
		.up_title {
			position: absolute;
			display: block;
			padding-left: 26px;
			width: 74px;
			color: #548b71;
			font-size: 14px;
			cursor: pointer;
		}
		.up_title:before {
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			width: 16px;
			height: 16px;
			border: 1px solid #548b71;
			border-radius: 4px;
			content: "+";
			text-align: center;
			font-weight: bold;
			font-size: 18px;
			line-height: 14px;
			transform: translateY(-50%);
		}
		input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 9;
			display: block;
			width: 90px;
			font-size: 24px;
			opacity: 0;
			cursor: pointer;
		}
		.img_wrap {
			position: relative;
			width: 40px;
			height: 40px;
			.upLoad_url {
				display: block;
				width: 40px !important;
				height: 40px !important;
			}
			.icon_delete {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 10;
				display: block;
				width: 14px;
				height: 14px;
				background: url("../../assets/img/backStageImg/delete-icon.png") center no-repeat;
				background-size: 14px;
				cursor: pointer;
			}
		}
	}
}
.pdfList {
	box-sizing: border-box;
	width: 148px;
	height: 148px;
	border-radius: 6px;
}
.upload {
	position: relative;
	width: 100%;
	height: 100%;
	color: #d8e0f0;
	span {
		display: inline-block;
		padding-top: 26px;
	}
	span:before {
		position: absolute;
		top: 50%;
		left: 46%;
		display: block;
		width: 16px;
		height: 16px;
		border-radius: 4px;
		content: "+";
		text-align: center;
		font-weight: bold;
		font-size: 22px;
		line-height: 14px;
		transform: translateY(-50%);
	}
}
.videoLogo {
	margin-top: 16px;
}
/deep/ .el-progress-circle {
	width: 102px !important;
	height: 102px !important;
}
</style>
