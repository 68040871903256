<template>
  <div class="percentage" :class="{'web_percentage': width}">
    <el-progress :width="66" type="circle" :percentage="percentage"></el-progress>
  </div>
</template>
<script>
export default {
  name: "progressDialog",
  props: ["percentage", "width"],
}
</script>
<style lang="scss" scoped>
.percentage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
 
  z-index: 66;
  background: #f3f3f3;
}
.web_percentage {
  padding: 0;
}
</style>


